var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":!_vm.$store.state.app.customerCreateOrder}},[_c('v-card-title',{staticClass:"pb-0 pt-2 px-2"},[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v("รายการสินค้า")])]),_c('v-divider',{staticClass:"mt-2 mb-1"}),_c('v-card-text',{staticClass:"d-flex px-2"},[_c('v-autocomplete',{staticClass:"me-2",staticStyle:{"width":"25%"},attrs:{"items":_vm.storeList,"filled":"","hide-details":"","dense":"","item-text":"store_name","return-object":"","no-data-text":"ไม่พบข้อมูล","label":"เลือกคลังสินค้า"},on:{"change":_vm.getProductList},model:{value:(_vm.storeSelection),callback:function ($$v) {_vm.storeSelection=$$v},expression:"storeSelection"}}),_c('v-text-field',{attrs:{"label":"ค้นหาสินค้า","placeholder":"รหัส, ชื่อ","filled":"","dense":"","hide-details":""},on:{"input":_vm.getStoreList},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productList,"fixed-header":"","footer-props":_vm.footer,"loading":_vm.loading,"mobile-breakpoint":"0","height":"350","dense":"","disable-sort":"","loading-text":"กำลังโหลดข้อมูล...","no-data-text":"ไม่พบข้อมูล"},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.product_code)+") "+_vm._s(item.product_name)+" ")]}},{key:"item.store_product_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.store_product_price)+" "),_c('CurrentCurrency')],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.addProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCartPlus))])],1)]}}],null,true)},[_c('span',[_vm._v("เพิ่มลงตะกร้า")])])]}}],null,true)})],1),_c('AddProductToCart',{attrs:{"data-product":_vm.productSelection},on:{"onAdd":function($event){_vm.getProductList();_vm.$emit('addProductToCart')}},model:{value:(_vm.isAddProductToCart),callback:function ($$v) {_vm.isAddProductToCart=$$v},expression:"isAddProductToCart"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }