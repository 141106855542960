import { ref } from "@vue/composition-api"
import { api } from "@/services/api";
import store from "@/store"

export default function useCreateOrder() {
    const dataCustomer = ({})
    const customerId = ref(null)
    const cartList = ref([])
    const cartLoading = ref(false)
    const statusCreate = ref(false)
    const statusDelete = ref(false)
    const orderDetail = ref({})
    const dateCreate = ref(null)

    const selectDateCreate = (date) => {
        dateCreate.value = date
    }

    const currentCustomer = (customer) => {
        dataCustomer.value = customer
        store.commit('app/setCustomerCreateOrder', customer ? customer.customer_id : null)
        getCartList()
    }

    const getCartList = async () => {
        cartLoading.value = true
        api.get({
            path: '/orderDetailTmp',
            param: `${ store.state.app.customerCreateOrder }?currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(res => {
            cartList.value = res.data
            cartLoading.value = false
            orderDetail.value = {
                orderDiscount: res.discount,
                orderTotal: res.total,
                orderAfterDiscount: res.priceAfterDiscount,
                priceBeforeDiscount: res.priceBeforeDiscount,
                orderAfterVat: res.orderAfterVat,
                vat: res.vat,
            }
        }).catch(err => {
            cartLoading.value = false
            console.log(err)
        })
    }


    return {
        dataCustomer,
        selectDateCreate,
        getCartList,
        currentCustomer,
        cartList,
        dateCreate,
        cartLoading,
        statusDelete,
        orderDetail,
        statusCreate
    }
}