<template>
  <div>
    <v-dialog v-model="isEditProductInCart" persistent max-width="550">
      <v-card>
        <v-card-title>
          ({{ dataProductLocal.product_id }}) {{ dataProductLocal.product_name }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="formEditProductInCart" @submit.prevent="editProductInCart">
          <v-row class="mx-2 my-2">
            <v-col cols="12" class="d-flex">
              <v-text-field
                  v-model="dataProductLocal.order_detail_tmp_price"
                  label="ราคา"
                  filled
                  dense
                  hide-details="auto"
                  readonly
                  class="me-2"
                  :rules="[required]"
              ></v-text-field>
              <v-text-field
                  v-model="dataProductLocal.order_detail_tmp_amount"
                  label="จำนวน"
                  filled
                  dense
                  hide-details="auto"
                  type="number"
                  :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class='d-flex flex-column'>
              <v-radio-group v-model="discountType" @change="getDiscounts" hide-details="auto" row>
                <v-radio
                    label="เปอร์เซ็นต์ส่วนลด"
                    value="1"
                ></v-radio>
                <v-radio
                    :label="`โค้ดส่วนลด`"
                    value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class='d-flex'>
              <v-select
                  v-model="dataProductLocal.order_detail_tmp_discount"
                  label="ส่วนลด"
                  filled
                  :items="discountPercentList"
                  item-text="percent_value"
                  item-value="percent_value"
                  class="me-2"
                  dense
                  hide-details="auto"
                  type="number"
                  :prepend-inner-icon="mdiPercentOutline"
              ></v-select>
              <v-select
                  v-model="dataProductLocal.order_detail_tmp_vat"
                  label="ภาษี"
                  :items="vatList"
                  filled
                  item-text="vat_value"
                  item-value="vat_value"
                  :append-icon="mdiPercentOutline"
                  dense
                  hide-details="auto"
                  type="number"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex py-0">
              <span class="font-weight-semibold">ราคา</span>
              <v-spacer></v-spacer>
              <span class="primary--text text-decoration-underline">
                {{
                  dataProductLocal.store_product_price * dataProductLocal.order_detail_tmp_amount | formatPrice
                }}</span>
            </v-col>

            <v-col cols="12" class="d-flex py-0">
              <span class="font-weight-semibold">ส่วนลด</span>
              <v-spacer></v-spacer>
              <span class="primary--text text-decoration-underline" v-if="discountType==1">
                {{
                  dataProductLocal.store_product_price * dataProductLocal.order_detail_tmp_amount * dataProductLocal.order_detail_tmp_discount / 100 || 0 | formatPrice
                }}</span>
              <span v-else class="primary--text text-decoration-underline">
                {{ dataProductLocal.order_detail_tmp_discount.promotion_discount_amount || 0 | formatPrice }}
              </span>
            </v-col>

            <v-col cols="12" class="d-flex py-0">
              <span class="font-weight-semibold">ภาษี</span>
              <v-spacer></v-spacer>
              <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                {{
                  dataProductLocal.store_product_price * dataProductLocal.order_detail_tmp_amount * dataProductLocal.order_detail_tmp_vat / 100 || 0 | formatPrice
                }}</span>
              <span class="primary--text text-decoration-underline" v-else>
                {{
                  (dataProductLocal.store_product_price - dataProductLocal.order_detail_tmp_discount.promotion_discount_amount) * dataProductLocal.order_detail_tmp_vat / 100 || 0 | formatPrice
                }}
              </span>
            </v-col>

            <v-col cols="12" class="d-flex py-0">
              <span class="font-weight-semibold">ราคารวม</span>
              <v-spacer></v-spacer>
              <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                {{
                  dataProductLocal | sumOrderTotal
                }}</span>
              <span class="primary--text text-decoration-underline" v-else>
                {{
                  dataProductLocal | sumOrderTotal2
                }}
              </span>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">บันทึก</v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:isEditProductInCart',false)">ยกเลิก</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api"
import { required } from "@/@core/utils/validation"
import { api } from "@/services/api"
import store from "@/store"
import { mdiPercentOutline } from '@mdi/js'
import { formatPrice, sumOrderTotal, convertStingToNumber, sumOrderTotal2 } from "@/services/filters";

export default {
  model: {
    prop: 'isEditProductInCart',
    event: 'update:isEditProductInCart',
  },
  props: {
    isEditProductInCart: {
      type: Boolean,
      default: false,
    },
    dataProduct: {
      type: Object,
      default: () => ({}),
    },

  },
  filters: {
    formatPrice, sumOrderTotal, sumOrderTotal2
  },
  components: {},
  setup(props, { root, emit }) {
    const dataProductLocal = ref({})
    const formEditProductInCart = ref(null)
    const loading = ref(false)
    const vatList = ref([])
    const discountPercentList = ref([])
    const discountType = ref('1')
    const getDiscounts = async () => {
      await api.get({
        path: `${ discountType.value == '1' ? '/percents' : '/promotionDiscounts' }`,
        param: `?search=&active=1&currency_id=${ store.state.app.currentCurrencyId }`,
      }).then(({ data }) => {
        discountPercentList.value = data
        if (discountType.value == '1') {
          discountPercentList.value.unshift({
            percent_id: '',
            percent_value: 0,
          })
          dataProductLocal.value.order_detail_tmp_discount = discountPercentList.value[0].percent_value
        } else {
          dataProductLocal.value.order_detail_tmp_discount = discountPercentList.value[0]
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }
    const getVat = () => {
      api.get({
        path: '/vats'
      }).then(res => {
        vatList.value = res.data
        vatList.value.unshift({
          vat_id: 0,
          vat_name: 'ไม่มีภาษี',
          vat_percent: 0,
          vat_value: 0,
        })
      }).catch(err => {
        console.log('error ', err)
      })
    }

    watch(() => props.isEditProductInCart, value => {
      if (value) {
        dataProductLocal.value = JSON.parse(JSON.stringify(props.dataProduct))
        dataProductLocal.value = {
          ...dataProductLocal.value,
          order_detail_tmp_vat: parseFloat(dataProductLocal.value.order_detail_tmp_vat),
          order_detail_tmp_discount: parseFloat(dataProductLocal.value.order_detail_tmp_discount),
        }
        console.log('dataProductLocal', dataProductLocal.value)
        getVat()
        getDiscounts()
      }
    })
    const editProductInCart = () => {
      const isFormValid = formEditProductInCart.value.validate()
      if (!isFormValid) return
      api.put({
        path: '/orderDetailTmp',
        param: props.dataProduct.order_detail_tmp_id,
        body: {
          customer_id: store.state.app.customerCreateOrder,
          store_product_id: dataProductLocal.value.store_product_id,
          currency_id: store.state.app.currentCurrencyId,
          product_id: dataProductLocal.value.product_id,
          order_detail_tmp_price: convertStingToNumber(dataProductLocal.value.order_detail_tmp_price),
          order_detail_tmp_amount: convertStingToNumber(dataProductLocal.value.order_detail_tmp_amount),
          order_detail_tmp_vat: dataProductLocal.value.order_detail_tmp_vat,
          // order_detail_tmp_discount: dataProductLocal.value.order_detail_tmp_discount,
          order_detail_tmp_discount: discountType.value == 1 ? convertStingToNumber(dataProductLocal.value.order_detail_tmp_discount) : '',
          promotion_discount_id: discountType.value == 2 ? dataProductLocal.value.order_detail_tmp_discount.order_detail_tmp_discount : ''
        }
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate',)
        emit('update:isEditProductInCart', false)
        loading.value = false
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })

    }
    return {
      loading,
      dataProductLocal,
      discountPercentList,
      formEditProductInCart,
      required,
      getDiscounts,
      mdiPercentOutline,
      vatList,
      discountType,
      editProductInCart,
    }
  },

}
</script>

<style scoped>

</style>
