<template>
    <div>
        <v-row>
            <v-col cols="12" lg="6">
                <CustomerData @customer-change="currentCustomer" :statusCreate="statusCreate"/>
            </v-col>
            <v-col cols="12" lg="6">
                <UserData @dateCreate="selectDateCreate"/>
            </v-col>
            <v-col cols="12" lg="5">
                <ProductList @addProductToCart="getCartList" :statusDelete="statusDelete"/>
            </v-col>
            <v-col cols="12" lg="7">
                <CartList :cartList="cartList" :loading="cartLoading"
                          :order-detail="orderDetail"
                          :dateCreate="dateCreate"
                          :dataCustomer="dataCustomer"
                          @createOrder="getCartList();statusCreate = !statusCreate"
                          @updateProductInCart="getCartList();statusDelete = !statusDelete"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import useCreateOrder from "@/views/manager/order/createOrder/useCreateOrder";
import CustomerData from "./CustomerData"
import UserData from "./UserData"
import ProductList from "./ProductList.vue"
import CartList from "./CartList"

export default {
    props: {},
    components: { CustomerData, UserData, ProductList, CartList },
    setup(_, { root, emit }) {
        return {
            ...useCreateOrder()
        }
    },

}
</script>

<style scoped>

</style>
