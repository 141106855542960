<template>
    <div>
        <v-card max-height="120" height="120">
            <v-card-title class="pb-0 pt-2 px-2">
                <h4 class="font-weight-semibold">ข้อมูลพนักงานขาย</h4>
            </v-card-title>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-card-text class="py-0 d-flex px-2">
                <span class="font-weight-semibold ">วันที่เปิดออเดอร์ : </span>
                <v-spacer></v-spacer>
                <span>
          <v-dialog
                  ref="dateStart"
                  v-model.trim="isShowDate"
                  width="290px"
          >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model.trim="date"
                                    label="วันที่สร้างออเดอร์"
                                    dense
                                    hide-details
                                    outlined
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            >
                            </v-text-field>
                        </template>
                        <v-date-picker
                                v-model.trim="date"
                                locale="th"
                                scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="selectDate()"
                            >
                                ยืนยัน
                            </v-btn>
                            <v-btn
                                    text
                                    color="secondary"
                                    @click="isShowDate = false"
                            >
                                ยกเลิก
                            </v-btn>

                            <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-dialog>
        </span>
            </v-card-text>
            <v-card-text class="pb-0 pt-2 d-flex px-2">
                <span class="font-weight-semibold">พนักงาน : </span>
                <v-spacer></v-spacer>
                <span>
          {{ userData.user_fname }} {{ userData.user_lname }}
        </span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { getByDate, sumdatetime } from "@/services/filters";

export default {
    props: {},
    components: {},
    setup(_, { root, emit }) {
        const date = ref(new Date().toISOString().substr(0, 10));
        const userData = ref({})
        const isShowDate = ref(false)

        onMounted(() => {
            userData.value = JSON.parse(localStorage.getItem('userData'))
        })

        const selectDate = () => {
            emit('dateCreate', date.value)
            isShowDate.value = false
        }

        return { userData, date, isShowDate, selectDate }
    },

}
</script>

<style scoped>

</style>
