<template>
    <div>
        <v-card max-height="120" height="120">
            <v-card-title class="pb-0 pt-2 px-2">
                <h4 class="font-weight-semibold">เลือกลูกค้า</h4>
            </v-card-title>
            <v-divider class="mt-2 mb-1"></v-divider>
            <v-card-title class="align-center justify-center d-flex pt-0 px-2">

                <v-autocomplete :items="customerList"
                                v-model="customerSelection"
                                filled dense label="เลือกลูกค้า"
                                class="me-2"
                                no-data-text="ไม่พบข้อมูล"
                                @change="$emit('customer-change', customerSelection)"
                                :search-input.sync="textSearch"
                                return-object
                                item-text="customer_select"
                                hide-details>
                </v-autocomplete>
                <v-btn color="primary" fab small @click="isAddCustomer = true">
                    <v-icon large>{{ mdiPlus }}</v-icon>
                </v-btn>
            </v-card-title>
        </v-card>
        <AddNewCustomer v-model="isAddCustomer" @onAdd="getCustomerList"/>
    </div>
</template>

<script>
import { api } from "@/services/api";
import { ref, onMounted, watch, onUnmounted } from "@vue/composition-api"
import { mdiPlus } from "@mdi/js";
import AddNewCustomer from "@/views/customers/AddNewCustomer";
import store from "@/store"

export default {
    props: {
        statusCreate: {
            type: Boolean,
            default: false
        }
    },
    components: { AddNewCustomer, },
    setup(props, { root, emit }) {
        const isAddCustomer = ref(false)
        const customerList = ref([])
        const customerSelection = ref({})
        const textSearch = ref('')

        onMounted(() => {
            getCustomerList()

        })

        onUnmounted(() => {
            store.commit('app/setCustomerCreateOrder', '')
        })

        const getCustomerList = async () => {
            api.get({
                path: '/customers',
                param: `?search=${ textSearch.value || '' }&active=1`,
            }).then(({ data }) => {
                customerList.value = data
            }).catch(err => {
                console.log(err)
            })
        }

        watch(() => props.statusCreate, () => {
            getCustomerList()
        })

        watch(textSearch, (value) => {
            getCustomerList()
        })


        return {
            customerList,
            customerSelection,
            getCustomerList,
            mdiPlus,
            isAddCustomer,
            textSearch,
        }
    },

}
</script>

<style scoped>


</style>
