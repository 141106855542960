<template>
  <div>
    <v-card :disabled="!$store.state.app.customerCreateOrder">
      <v-card-title class="pb-0 pt-2 px-2">
        <h4 class="font-weight-semibold">รายการสินค้า</h4>
      </v-card-title>
      <v-divider class="mt-2 mb-1"></v-divider>
      <v-card-text class="d-flex px-2">
        <v-autocomplete :items="storeList" v-model="storeSelection" filled hide-details
                        dense
                        item-text="store_name"
                        class="me-2"
                        style="width: 25%"
                        return-object
                        no-data-text="ไม่พบข้อมูล"
                        @change="getProductList"
                        label="เลือกคลังสินค้า"></v-autocomplete>
        <v-text-field v-model="searchText" label="ค้นหาสินค้า" placeholder="รหัส, ชื่อ" filled dense hide-details
                      @input="getStoreList">

        </v-text-field>
      </v-card-text>
      <v-data-table
          :headers='headers'
          :items='productList'
          fixed-header
          :footer-props='footer'
          :loading='loading'
          mobile-breakpoint='0'
          height='350'
          dense
          disable-sort
          loading-text="กำลังโหลดข้อมูล..."
          no-data-text="ไม่พบข้อมูล"
      >
        <template v-slot:[`item.product_name`]='{ item }'>
          ({{ item.product_code }}) {{ item.product_name }}
        </template>
        <template v-slot:[`item.store_product_price`]='{ item }'>
          <span>{{ item.store_product_price }} <CurrentCurrency/></span>
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn icon small @click="addProduct(item)" color="primary" v-bind='attrs'
                     v-on='on'>
                <v-icon>{{ mdiCartPlus }}</v-icon>
              </v-btn>
            </template>
            <span>เพิ่มลงตะกร้า</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddProductToCart v-model="isAddProductToCart" :data-product="productSelection"
                      @onAdd="getProductList();$emit('addProductToCart')"/>
  </div>
</template>

<script>
import { api } from "@/services/api"
import { ref, onMounted, watch } from "@vue/composition-api"
import dataFooterTables from "@/fake-db/footerDataTables-sm.json"
import { mdiCartPlus } from "@mdi/js"
import AddProductToCart from "./AddProductToCart";
import store from "@/store";
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
  props: {
    statusDelete: {
      type: Boolean,
      default: false
    }
  },
  components: { CurrentCurrency, AddProductToCart },
  setup(props, { root, emit }) {
    const searchText = ref("")
    const storeList = ref([])
    const storeSelection = ref({})
    const productList = ref([])
    const productSelection = ref(null)
    const footer = ref(dataFooterTables)
    const isAddProductToCart = ref(false)
    const headers = ref([
      {
        text: '(รหัส) ชื่อสินค้า',
        value: 'product_name',
        width: 200,
      },
      {
        text: 'ราคา',
        value: 'store_product_price',
        align: 'right',
        width: 120,
      },
      {
        text: 'คงเหลือ',
        value: 'store_product_balance',
        width: 100,
        align: 'right',
      },
      {
        text: 'เลือก',
        value: 'actions',
        align: 'right fixed',

      },
    ])
    const loading = ref(false)

    onMounted(() => {
      getStoreList()
    })

    const getStoreList = async () => {
      api.get({
        path: '/stores',
      }).then(({ data }) => {
        storeList.value = data
        getProductList()
      }).catch(err => {
        console.log(err)
      })
    }

    const getProductList = async () => {
      api.get({
        path: '/store',
        param: `${ storeSelection.value.store_id }?search=${ searchText.value }&customer_id=${ store.state.app.customerCreateOrder }&currency_id=${ localStorage.getItem('currencyId') }`
      }).then(({ data }) => {
        productList.value = data
      }).catch(err => {
        console.log(err)
      })
    }

    watch(() => props.statusDelete, value => {
      getProductList()
    })

    watch(() => store.state.app.customerCreateOrder, value => {
      getProductList()
    })

    const addProduct = (product) => {
      productSelection.value = product
      isAddProductToCart.value = true
    }

    return {
      storeList,
      searchText,
      storeSelection,
      productList,
      productSelection,
      footer,
      loading,
      headers,
      mdiCartPlus,
      isAddProductToCart,
      addProduct,
      getStoreList,
      getProductList,
    }
  },

}
</script>

<style scoped>

</style>
