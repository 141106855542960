<template>
    <div>
        <v-dialog v-model="isAddProductToCart" persistent max-width="550">
            <v-card>
                <v-card-title>
                    {{ dataProductLocal.product_name }}
                    <v-spacer></v-spacer>
                    <span
                            class="primary--text">คลัง : {{ dataProductLocal.store_name }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-form ref="formAddProductToCart" @submit.prevent="addProductToCart">
                    <v-row class="mx-2 my-2">
                        <v-col cols="12" class="d-flex pb-0">
                            <v-text-field
                                    v-model="dataProductLocal.store_product_price"
                                    label="ราคา"
                                    filled
                                    dense
                                    hide-details="auto"
                                    readonly
                                    class="me-2"
                            ></v-text-field>
                            <v-text-field
                                    v-model="dataProductLocal.order_detail_tmp_amount"
                                    label="จำนวน"
                                    filled
                                    dense
                                    hide-details="auto"
                                    type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class='d-flex flex-column'>
                            <v-radio-group v-model="discountType" @change="getDiscounts" hide-details="auto" row>
                                <v-radio
                                        label="เปอร์เซ็นต์ส่วนลด"
                                        value="1"
                                ></v-radio>
                                <v-radio
                                        :label="`โค้ดส่วนลด`"
                                        value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" class='d-flex'>
                            <v-select
                                    v-model="dataProductLocal.order_detail_tmp_discount"
                                    :label="`ส่วนลด ${discountType==='1'?'(%)':''}`"
                                    no-data-text="ไม่มีข้อมูล"
                                    filled
                                    :items="discountPercentList"
                                    :item-text="discountType ==1?`percent_value`:`promotion_discount_code`"
                                    :item-value="discountType ==1?`percent_value`:`promotion_discount_id`"
                                    class="me-2"
                                    dense
                                    hide-details="auto"
                                    :return-object="discountType==2"
                                    type="number"

                            ></v-select>
                            <v-select
                                    v-model="dataProductLocal.order_detail_tmp_vat"
                                    label="ภาษี"
                                    filled
                                    no-data-text="ไม่มีข้อมูล"
                                    :items="vatList"
                                    item-text="vat_value"
                                    item-value="vat_value"
                                    dense
                                    hide-details="auto"
                                    :prepend-inner-icon="mdiPercentOutline"
                                    type="number"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ราคา</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline">
                {{
                                    dataProductLocal|sumOrderTotal3
                                }}</span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ส่วนลด</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType==1">
                {{
                                    dataProductLocal | sumOrderTotal4
                                }}</span>
                            <span v-else class="primary--text text-decoration-underline">
                {{ +dataProductLocal.order_detail_tmp_discount.promotion_discount_amount || 0 | formatPrice }}
              </span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ภาษี</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                  {{ dataProductLocal | sumOrderTotalVat }}
              </span>
                            <span class="primary--text text-decoration-underline" v-else>
                {{ dataProductLocal | sumOrderTotalVat2 }}
              </span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ราคารวม</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                {{
                                    dataProductLocal | sumOrderTotal
                                }}</span>
                            <span class="primary--text text-decoration-underline" v-else>
                {{
                                    dataProductLocal | sumOrderTotal2
                                }}
              </span>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">บันทึก</v-btn>
                        <v-btn color="secondary" outlined @click="$emit('update:isAddProductToCart',false)">ยกเลิก
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api"
import { required } from "@/@core/utils/validation"
import { api } from "@/services/api"
import store from "@/store"
import { mdiPercentOutline } from "@mdi/js"
import {
    formatPrice,
    sumOrderTotal,
    sumOrderTotal2,
    sumOrderTotalVat,
    sumOrderTotal3,
    sumOrderTotal4,
    sumOrderTotalVat2,
} from "@/services/filters";
import { convertStingToNumber } from "@/services/filters"

export default {
    model: {
        prop: 'isAddProductToCart',
        event: 'update:isAddProductToCart',
    },
    props: {
        isAddProductToCart: {
            type: Boolean,
            default: false,
        },
        dataProduct: {
            type: Object,
            default: () => ({}),
        },

    },
    filters: {
        sumOrderTotalVat,
        formatPrice,
        sumOrderTotal,
        sumOrderTotal2,
        sumOrderTotal3,
        sumOrderTotal4,
        sumOrderTotalVat2
    },
    components: {},
    setup(props, { root, emit }) {
        const dataProductLocal = ref({})
        const formAddProductToCart = ref(null)
        const loading = ref(false)
        const vatList = ref([])
        const discountPercentList = ref([])
        const discountType = ref('1')

        const updateTypeDiscount = (e) => {
            console.log(e)
        }

        const getDiscounts = async () => {
            discountPercentList.value = []
            await api.get({
                path: `${ discountType.value == '1' ? '/percents' : '/promotionDiscounts' }`,
                param: `?search=&active=1&currency_id=${ store.state.app.currentCurrencyId }`,
            }).then(({ data }) => {
                discountPercentList.value = data
                if (discountType.value == '1') {
                    discountPercentList.value.unshift({
                        percent_id: '',
                        percent_value: 0,
                    })
                    dataProductLocal.value.order_detail_tmp_discount = discountPercentList.value[0].percent_value || 0
                } else {
                    dataProductLocal.value.order_detail_tmp_discount = discountPercentList.value[0] || 0
                }
            }).catch(err => {
                console.log('error : ', err)
            })
        }

        const getVat = () => {
            api.get({
                path: '/vats'
            }).then(res => {
                vatList.value = res.data
                vatList.value.unshift({
                    vat_id: 0,
                    vat_name: 'ไม่มีภาษี',
                    vat_percent: 0,
                    vat_value: 0,
                })
                dataProductLocal.value.order_detail_tmp_vat = 0
            }).catch(err => {
                console.log('error ', err)
            })
        }


        watch(() => props.isAddProductToCart, value => {
            if (value) {
                dataProductLocal.value = JSON.parse(JSON.stringify(props.dataProduct))
                dataProductLocal.value = {
                    ...dataProductLocal.value,
                    order_detail_tmp_amount: 1,
                    order_detail_tmp_discount: 0,
                    order_detail_tmp_vat: 0
                }
                getVat()
                getDiscounts()
            }
        })

        const addProductToCart = () => {
            const isFormValid = formAddProductToCart.value.validate()
            if (!isFormValid) return
            api.post({
                path: '/orderDetailTmp',
                body: {
                    customer_id: store.state.app.customerCreateOrder,
                    store_product_id: dataProductLocal.value.store_product_id,
                    currency_id: store.state.app.currentCurrencyId,
                    product_id: dataProductLocal.value.product_id,
                    order_detail_tmp_price: convertStingToNumber(dataProductLocal.value.store_product_price),
                    order_detail_tmp_amount: convertStingToNumber(dataProductLocal.value.order_detail_tmp_amount),
                    order_detail_tmp_vat: dataProductLocal.value.order_detail_tmp_vat,
                    order_detail_tmp_discount: discountType.value == 1 ? convertStingToNumber(dataProductLocal.value.order_detail_tmp_discount) : '',
                    promotion_discount_id: discountType.value == 2 ? dataProductLocal.value.order_detail_tmp_discount.promotion_discount_id : ''
                }
            }).then(res => {
                store.commit('app/ALERT', {
                    message: res.message,
                    color: res.response ? 'success' : 'error',
                })
                emit('onAdd',)
                emit('update:isAddProductToCart', false)
                loading.value = false
            }).catch(err => {
                console.log('error : ', err)
                loading.value = false
            })

        }

        return {
            loading,
            dataProductLocal,
            formAddProductToCart,
            discountType,
            discountPercentList,
            getDiscounts,
            vatList,
            updateTypeDiscount,
            required,
            addProductToCart,
            mdiPercentOutline,
        }
    },

}
</script>

<style scoped>

</style>
