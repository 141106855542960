<template>
    <div>
        <v-card height="280" max-height="280" :disabled="!$store.state.app.customerCreateOrder">
            <v-card-title class="pb-0 pt-2 px-2">
                <h4 class="font-weight-semibold">ตะกร้าสินค้า</h4>
                <v-spacer></v-spacer>
                <span class="primary--text">ราคารวม (Exclude VAT) : {{ (orderDetail.priceBeforeDiscount || 0)  |formatPrice
                    }} <CurrentCurrency/></span>
            </v-card-title>
            <v-divider class="mt-2"></v-divider>
            <v-data-table
                    :headers='headers'
                    :items='cartList'
                    fixed-header
                    hide-default-footer
                    :loading='loading'
                    height="240"
                    mobile-breakpoint='0'
                    dense
                    disable-sort
                    loading-text="กำลังโหลดข้อมูล..."
                    no-data-text="ไม่พบข้อมูล"
            >
                <template v-slot:[`item.product_name`]='{ item }'>
                    <v-tooltip
                            color="#212121"
                            right
                    >
                        <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon color="primary">{{ mdiInformationOutline }}</v-icon>
                </span>
                        </template>
                        <div class="">
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold me-2">ราคาก่อนหักส่วนลด :</div>
                                <div class="font-weight-bold">
                                    {{ item.product_detail.amount |formatPrice }}
                                    <CurrentCurrency/>
                                </div>
                            </div>
                            <div class="d-flex justify-space-between">
                                <span class="font-weight-bold">ส่วนลด : </span>
                                <span class="font-weight-bold">
                  {{ item.product_detail.discount | formatPrice }} <CurrentCurrency/>
                </span>
                            </div>
                            <div class="d-flex justify-space-between">
                                <span class="font-weight-bold">ภาษี : </span>
                                <span class="font-weight-bold">
                  {{ item.product_detail.vat | formatPrice }}
                <CurrentCurrency/>
                </span>
                            </div>
                            <div class="d-flex justify-space-between">
                                <span class="font-weight-bold">รวม : </span>
                                <span class="font-weight-bold">
                  {{ item.product_detail.total | formatPrice }}
                <CurrentCurrency/>
                </span>
                            </div>
                        </div>
                    </v-tooltip>
                    <span class="ms-1">{{ item.product_name }}</span>
                </template>
                <template v-slot:[`item.order_detail_tmp_discount`]='{ item }'>
                    {{ item.order_detail_tmp_discount }}
                </template>
                <template v-slot:[`item.order_detail_tmp_vat`]='{ item }'>
                    {{ item.order_detail_tmp_vat }}
                </template>
                <template v-slot:[`item.order_detail_tmp_price`]='{ item }'>
                    {{ item.order_detail_tmp_price }}
                    <CurrentCurrency/>
                </template>
                <template v-slot:[`item.order_detail_tmp_total`]='{ item }'>
                    {{ item.order_detail_tmp_total }}
                    <CurrentCurrency/>
                </template>
                <template v-slot:[`item.actions`]='{ item }'>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn icon small color="error" v-bind='attrs'
                                   @click="dataEdit = item ;isRemove = true"
                                   v-on='on'>
                                <v-icon>{{ mdiDeleteOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>ลบ</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <div class="d-flex align-center justify-center">
            <v-card color="mt-2" :disabled="!$store.state.app.customerCreateOrder" width="50%" height="234.2"
                    max-height="234.2">
                <v-card-title class="pb-0 pt-2 px-2">
                    <h4 class="font-weight-semibold">
                        ค่าขนส่ง
                    </h4>
                </v-card-title>
                <v-divider class="mt-2"></v-divider>
                <v-card-text class="px-2">
                    <v-select :items="transportList" v-model="transportSelection" label="เลือกช่องทางขนส่ง" dense
                              outlined
                              hide-details
                              @change="transportChange"
                              return-object
                              no-data-text="ไม่มีข้อมูล" item-text="transport_name" item-value="transport_id">
                    </v-select>
                    <div class="d-flex mt-3">
                        <v-text-field v-model="transportPrice" label="ราคาขนส่ง/กล่อง" dense outlined
                                      class="me-1"
                                      hide-details
                                      reverse
                                      disabled
                                      type="number"
                                      no-data-text="ไม่มีข้อมูล" item-text="transport_name" item-value="transport_id">
                        </v-text-field>
                        <v-text-field v-model="transportAmount" label="จำนวนกล่องพัสดุ" dense outlined
                                      hide-details
                                      reverse
                                      type="number"
                                      no-data-text="ไม่มีข้อมูล" item-text="transport_name" item-value="transport_id">
                        </v-text-field>
                    </div>
                    <div class="d-flex align-center justify-space-between font-weight-semibold mt-11">
                        <span>ค่าจัดส่งรวม</span>
                        <span class="primary--text">
              {{ transportPrice * transportAmount |formatPrice }} <CurrentCurrency/>
            </span>
                    </div>
                </v-card-text>
            </v-card>
            <v-card color="mt-2 ms-2" :disabled="!$store.state.app.customerCreateOrder" width="50%" height="234.2"
                    max-height="234.2">
                <v-card-title class="pb-0 pt-2 px-2">
                    <h4 class="font-weight-semibold">
                        สรุปยอดออเดอร์
                    </h4>
                </v-card-title>
                <v-divider class="mt-2"></v-divider>
                <v-card-text class="px-2 pt-0 d-flex flex-column">
                    <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
                        <span>ส่วนลด</span>
                        <span class="primary--text">
              {{ (orderDetail.orderDiscount || 0)|formatPrice }} <CurrentCurrency/>
            </span>
                    </div>
                    <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
            <span>
              ภาษีรวม
            </span>
                        <span class="primary--text">
              {{
                                (orderDetail.vat || 0)| formatPrice
                            }}
              <CurrentCurrency/>
            </span>
                    </div>
                    <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
                        <span>ราคารวม (Include VAT)</span>
                        <span class="primary--text">
              {{ (orderDetail.orderAfterVat || 0)|formatPrice }} <CurrentCurrency/>
            </span>
                    </div>
                    <h3 class="d-flex align-center justify-space-between my-1 ">
                        <span class="error--text">ราคารวม + ค่าจัดส่ง</span>
                        <span class="primary--text">

          {{
                                (orderDetail.orderAfterVat + (transportPrice * transportAmount) || 0)| formatPrice

                            }}
              <CurrentCurrency/>
            </span>
                    </h3>
                    <v-btn color="primary" block rounded class="mt-6" @click="isConfirmCreateOrder = true"
                           :disabled="!cartList.length || transportList[0].transport_id === transportSelection.transport_id">
                        <v-icon class="me-1">{{ mdiReceipt }}</v-icon>
                        สร้างออเดอร์
                    </v-btn>
                </v-card-text>

            </v-card>
        </div>
        <!--    <EditProductInCart v-model="isEdit" :data-product="dataEdit" @onUpdate="$emit('updateProductInCart')"/>-->
        <SuspendProductInCart v-model="isRemove" :data-update="dataEdit" @onUpdate="$emit('updateProductInCart')"/>
        <v-dialog v-model="isConfirmCreateOrder" persistent max-width="500">
            <v-card v-if="isConfirmCreateOrder">
                <v-card-title class="px-2">
          <span class="">
            ยืนยันการสั่งซื้อ
          </span>
                    <v-spacer></v-spacer>
                    <span class="primary--text text-decoration-underline">
           รวม : {{
                            ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) + ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) * vatSelection.vat_value / 100 |formatPrice
                        }}
          </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-form ref="formCreateOrder" @submit.prevent="createOrder">
                    <v-card-text class="px-0 py-0 ">
                        <div class="font-weight-bold px-2 text-decoration-underline d-flex">
            <span>
              ข้อมูลออเดอร์
            </span>

                        </div>
                        <div class="px-2">
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              ลูกค้า :
            </span>
                                <span>({{ dataCustomer.value.customer_code }}) {{
                                        dataCustomer.value.customer_fname
                                    }}</span>
                            </div>
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              วันที่เปิดออเดอร์ :
            </span>
                                <span>{{ date }}</span>
                            </div>
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              พนักงานขาย :
            </span>
                                <span>   {{ userData.user_lname }}</span>
                            </div>
                        </div>
                        <v-divider class="mt-2"></v-divider>
                        <div class="font-weight-bold px-2 text-decoration-underline d-flex">
                            <span>ข้อมูลการจัดส่ง</span>
                            <v-spacer></v-spacer>
                            <span>{{ transportSelection.transport_name }}</span>
                        </div>
                        <div class="px-1 pt-2">
                            <v-row no-gutters>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.value.customer_phone'
                                                  dense
                                                  hide-details='auto'
                                                  label='เบอร์โทรศัพท์'
                                                  placeholder='เบอร์โทรศัพท์'
                                                  outlined
                                                  :rules='[required]'
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.value.customer_lname' dense
                                                  hide-details='auto'
                                                  label='ชื่อผู้รับสินค้า'
                                                  placeholder='ชื่อผู้รับสินค้า'
                                                  outlined
                                                  :rules='[required]'
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.PROVINCE_NAME'
                                            label='จังหวัด'
                                            placeholder="จังหวัด"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.DISTRICT_NAME'
                                            label='อำเภอ'
                                            placeholder="อำเภอ"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.SUB_DISTRICT_NAME'
                                            label='ตำบล'
                                            hide-details='auto'
                                            placeholder=""
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='dataCustomer.value.customer_address2'
                                            label='หมู่บ้านหรือถนน'
                                            placeholder="หมู่บ้านหรือถนน"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.ZIPCODE'
                                            label='รหัสไปรษณีย์'
                                            placeholder="รหัสไปรษณีย์"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >

                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.value.customer_address' dense
                                                  hide-details='auto'
                                                  label='ที่อยู่ - เพิ่มเติม'
                                                  placeholder='ที่อยู่ - เพิ่มเติม'
                                                  outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-dialog
                                            ref="dateStart"
                                            v-model.trim="isShowDate"
                                            :return-value.sync="order_transportation_date"
                                            width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model.trim="order_transportation_date"
                                                    label="เลือกวันที่จัดส่ง"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    :rules="[required]"
                                                    v-on="on"
                                                    :prepend-inner-icon="mdiCalendar"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model.trim="order_transportation_date"
                                                locale="th"
                                                scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dateStart.save(order_transportation_date)"
                                            >
                                                ยืนยัน
                                            </v-btn>
                                            <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="isShowDate = false"
                                            >
                                                ยกเลิก
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1">
                                    <v-textarea
                                            v-model='dataCustomer.value.customer_note'
                                            label="หมายเหตุ"
                                            placeholder="หมายเหตุ เพิ่มเติมเกี่ยวกับการส่งสินค้า"
                                            outlined
                                            dense
                                            hide-details='auto'
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="loadingCreate" :disabled="loadingCreate">
                            บันทึก
                        </v-btn>
                        <v-btn color="secondary" outlined @click="isConfirmCreateOrder = false">
                            ยกเลิก
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { api } from "@/services/api"
import { ref, onMounted, watch } from "@vue/composition-api"
import {
    mdiCartPlus,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiReceipt,
    mdiCalendar,
    mdiInformationOutline
} from "@mdi/js"
import EditProductInCart from "@/views/manager/order/createOrder/EditProductInCart";
import SuspendProductInCart from "@/views/manager/order/createOrder/SuspendProductInCart";
import { formatPrice, sumdatetime, getByDate, convertStingToNumber } from "@/services/filters";
import { required } from "@core/utils/validation";
import location_new from "@/fake-db/location_new.json";
import store from "@/store"
import CurrentCurrency from "@/components/CurrentCurrency";
import { useRouter } from "@core/utils";

export default {
    props: {
        cartList: {
            type: Array,
            default: () => []
        },
        orderDetail: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false
        },
        dataCustomer: {
            type: Object,
            default: () => ({})
        },
        dateCreate: {
            type: String,
            default: ''
        },
    },
    filters: {
        formatPrice,
        convertStingToNumber

    },
    components: { CurrentCurrency, SuspendProductInCart, EditProductInCart },
    setup(props, { root, emit }) {
        const moment = require('moment')
        const productSelection = ref(null)
        const isEdit = ref(false)
        const isShowDate = ref(false)
        const isRemove = ref(false)
        const isConfirmCreateOrder = ref(false)
        const dataEdit = ref({})
        const headers = ref([
            {
                text: '(รหัส) ชื่อสินค้า',
                value: 'product_name',
                width: 200,
            },
            {
                text: 'ราคา',
                value: 'order_detail_tmp_price',
                align: 'end',
                width: 150
            },
            {
                text: 'จำนวน',
                value: 'order_detail_tmp_amount',
                align: 'end',
                width: 70
            },
            {
                text: 'ส่วนลด',
                value: 'order_detail_tmp_discount',
                align: 'end',
                width: 70
            },
            {
                text: 'ภาษี',
                value: 'order_detail_tmp_vat',
                align: 'end',
                width: 70
            },
            {
                text: 'ราคารวม',
                value: 'order_detail_tmp_total',
                align: 'end',
                width: 150
            },
            {
                text: 'เลือก',
                value: 'actions',
                align: 'end fixed',
                width: 95
            },
        ])
        const transportList = ref([])
        const { router } = useRouter()
        const transportSelection = ref({
            transport_id: '',
        })
        const order_transportation_date = ref(moment(new Date()).format('YYYY-MM-DD'))
        const transportPrice = ref(0)
        const transportAmount = ref(0)
        const loadingCreate = ref(false)
        const formCreateOrder = ref(null)
        const vatList = ref([])
        const vatSelection = ref({})
        const date = ref(sumdatetime(getByDate().dateTime))
        const userData = ref({})
        const locationList = ref(location_new)
        const address2 = ref('')
        const address = ref({
            SUB_DISTRICT_NAME: '',
            DISTRICT_NAME: '',
            PROVINCE_NAME: '',
            ZIPCODE: '',
        })
        const currentTime = ref('')
        onMounted(() => {
            getTransports()
            getVat()
            userData.value = JSON.parse(localStorage.getItem('userData'))


        })

        const getCurrentTime = () => {
            return moment(new Date()).format('HH:mm:ss')
        }

        const getVat = () => {
            api.get({
                path: '/vats'
            }).then(res => {
                vatList.value = res.data
                vatList.value.unshift({
                    vat_id: 0,
                    vat_name: 'ไม่มีภาษี',
                    vat_percent: 0,
                    vat_value: 0,
                })
                vatSelection.value = res.data[0]
            }).catch(err => {
                console.log('error ', err)
            })
        }

        const transportChange = () => {
            transportPrice.value = transportSelection.value.transport_price_default
        }

        const getTransports = () => {
            api.get({
                path: '/transports',
                param: `?active=1`,
            }).then(({ data }) => {
                transportList.value = data
                transportSelection.value = data[0]
                transportPrice.value = +transportSelection.value.transport_price_default
            }).catch(err => {
                console.log('err : ', err)
            })
        }

        watch(() => store.state.app.customerCreateOrder, (val) => {
            if (val) {
                address.value = {
                    SUB_DISTRICT_NAME: props.dataCustomer.value.customer_subdistrict,
                    DISTRICT_NAME: props.dataCustomer.value.customer_district,
                    PROVINCE_NAME: props.dataCustomer.value.customer_province,
                    ZIPCODE: props.dataCustomer.value.customer_zipcode,
                    SEARCH: `${ props.dataCustomer.value.customer_subdistrict } > ${ props.dataCustomer.value.customer_district } > ${ props.dataCustomer.value.customer_province } > ${ props.dataCustomer.value.customer_zipcode }`,
                }
            }
        })


        const createOrder = () => {
            const isFormValid = formCreateOrder.value.validate()
            if (!isFormValid) return

            loadingCreate.value = true


            const dateTime = `${ props.dateCreate ? props.dateCreate : new Date().toISOString().substr(0, 10) } ${ getCurrentTime() }`

            const body = {
                customer_id: store.state.app.customerCreateOrder,
                transport_id: transportSelection.value.transport_id,
                currency_id: store.state.app.currentCurrencyId,
                order_create: dateTime,
                order_transportation_price: transportPrice.value,
                order_transportation_amount: transportAmount.value,
                order_transportation_name: props.dataCustomer.value.customer_lname,
                order_transportation_address: `${ props.dataCustomer.value.customer_address }`,
                order_transportation_address2: `${ props.dataCustomer.value.customer_address2 }`,
                order_transportation_subdistrict: address.value.SUB_DISTRICT_NAME,
                order_transportation_district: address.value.DISTRICT_NAME,
                order_transportation_province: address.value.PROVINCE_NAME,
                order_transportation_zipcode: address.value.ZIPCODE,
                order_transportation_status: '1',
                order_transportation_phone: props.dataCustomer.value.customer_phone,
                order_transportation_date: order_transportation_date.value,
                order_transportation_note: props.dataCustomer.value.order_transportation_note,
                order_vat: vatSelection.value.vat_value,
            }
            api.post({
                path: '/order',
                body: body,
            }).then(res => {
                store.commit('app/ALERT', {
                    message: res.message,
                    color: res.response ? 'success' : 'error',
                })
                if (res.response) {
                    isConfirmCreateOrder.value = false
                    emit('createOrder', res.data)
                    store.commit('app/setCustomerCreateOrder', null)
                    loadingCreate.value = false
                    transportAmount.value = 0;
                    transportPrice.value = 0;
                    router.push({
                        name: 'order-list',
                    })

                }
            }).catch(err => {
                console.log('err : ', err)
            }).finally(() => {
                loadingCreate.value = false
            })
        }


        return {
            productSelection,
            headers,
            transportList,
            transportPrice,
            transportAmount,
            required,
            transportSelection,
            vatList,
            vatSelection,
            location_new,
            address,
            mdiCartPlus,
            transportChange,
            isEdit,
            dataEdit,
            mdiDeleteOutline, mdiPencilOutline, mdiReceipt,
            isRemove,
            isConfirmCreateOrder,
            formCreateOrder,
            isShowDate,
            locationList,
            address2,
            mdiCalendar,
            order_transportation_date,
            mdiInformationOutline,
            loadingCreate,
            date,
            userData,
            createOrder,
        }
    },

}
</script>

<style scoped>

</style>
